
import * as storyblokRuntime$h2NOgtcQH4MF95QEznei_6gzjtNU462X_9iKEUjnT0w from '/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxRuntime$bBdsagQkm6z5N0ck3pEedTO8fS0tdwb16DUoZ8mmJoc from '/codebuild/output/src4263495140/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$h2NOgtcQH4MF95QEznei_6gzjtNU462X_9iKEUjnT0w, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['ipx']: { provider: ipxRuntime$bBdsagQkm6z5N0ck3pEedTO8fS0tdwb16DUoZ8mmJoc, defaults: {} }
}
        